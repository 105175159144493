button {
  background-color: transparent;
}

h1, h2, h3, h4 {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

time {
  display: block;
}